import { PageHeader } from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

import { Breadcrumb } from "../../components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";
import { SecondaryTabs } from "../../components/SecondaryTabs";

import { StyledTitle, StyledSubTitle } from "./styled";
import { ExpenseBilling } from "@flash-hros/expense-billing";
import { PeopleBilling } from "@flash-hros/people-billing";
import {
  EmbeddedFinanceBillsPage,
  EmbeddedSettingsPage,
} from "@flash-hros/finance";

const BillingMainPage = () => {
  const [selectedSecondaryTab, setSelectedSecondaryTab] =
    useState<any>(undefined);

  // const { state } = useLocation();
  // const { tab: redirectedTab } = (state as any) || {};
  const navigate = useNavigate();

  const billingPages = [
    {
      key: "0-finance-bills-page",
      title: "Geral",
      component: <EmbeddedFinanceBillsPage />,
    },
    // { key: 1, title: "Gestão de Benefícios", component: <></> },
    {
      key: "2-people-billing",
      title: "Gestão de Pessoas",
      component: <PeopleBilling />,
    },
    {
      key: "3-expense-billing",
      title: "Gestão de Despesas",
      component: <ExpenseBilling />,
    },
    {
      key: "4-settings-page",
      title: "Configurações",
      component: <EmbeddedSettingsPage />,
    },
  ];

  useEffect(() => {
    // setSelectedSecondaryTab(billingPages[redirectedTab || 0]);
    setSelectedSecondaryTab(billingPages[0]);
  }, []);

  return (
    <>
      <PageHeader>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/settings`, { state: { tab: 2 } });
                },
                label: "Gerenciar empresa",
                active: true,
              },
              {
                onClick: () => ({}),
                label: "Pagamento e cobranças",
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <Grid container spacing={3} style={{ padding: "0px 32px 32px 32px" }}>
        <Grid item md={12} xs={12}>
          <StyledTitle variant="headline6">Pagamento e cobranças</StyledTitle>
          <StyledSubTitle variant="body4">
            Visualize e gerencie seus pagamentos e cobranças.
          </StyledSubTitle>
        </Grid>
        <Grid item md={2} xs={12}>
          <>
            <SecondaryTabs
              secondaryTabs={billingPages}
              selectedSecondaryTab={selectedSecondaryTab}
              setSelectedSecondaryTab={setSelectedSecondaryTab}
            />
          </>
        </Grid>
        <Grid item md={10} xs={12}>
          {selectedSecondaryTab?.component}
        </Grid>
      </Grid>
    </>
  );
};

export { BillingMainPage };
