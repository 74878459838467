import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export { StyledTitle, StyledSubTitle };
