import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BillingMainPage } from "../page/BillingMainPage";
import { PeopleBilling } from "@flash-hros/people-billing";
import { RoutesGuard } from "./routesGuard";
import { useRegisterRoutes } from "@flash-tecnologia/hros-web-utility";

export const routes = {
  pageMain: "/billing",
  pageChangePaymentMethod: "/billing/payment-method",
  pageInvoiceDetails: "/billing/invoice/:invoiceId",
} as const;

export const slugRoutes = [
  {
    slug: "core_manage_company_settings",
    routes: [
      routes.pageMain,
      routes.pageChangePaymentMethod,
      routes.pageInvoiceDetails,
    ],
  },
];

const AppRouter = () => {
  useRegisterRoutes([
    {
      path: routes.pageMain,
      hideHeader: false,
    },
    {
      path: routes.pageChangePaymentMethod,
      hideHeader: false,
    },
    {
      path: routes.pageInvoiceDetails,
      hideHeader: false,
    },
  ]);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RoutesGuard />}>
          <Route path="/billing" element={<BillingMainPage />} />
          <Route path="/billing/payment-method" element={<PeopleBilling />} />
          <Route
            path="/billing/invoice/:invoiceId"
            element={<PeopleBilling />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
